<template>
  <div
    style="
      background-color: rgba(0, 0, 0, 0.7);
      position: relative;
      height: 100%;
      width: 100%;
    "
  >
    <div
      oncontextmenu="return false;"
      :style="
        $route.query.video != 'true' && $route.query.video != true
          ? 'background-color: #F0F0F0!important'
          : ''
      "
      style="
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        aspect-ratio: 16/9;
        width: 100%;
      "
    >
      <div
        style="
          position: relative;
          aspect-ratio: 16/9;
          object-fit: cover;
          width: 100%;
          user-drag: none;
        "
        v-if="!notFound"
      >
        <div v-if="$route.query.video != 'true' && $route.query.video != true">
          <v-img contain :src="imageUrl">
            <a
              class="pr-2 pl-2"
              style="
                padding: 0px !important;
                background-color: rgba(255, 255, 255, 0.8);
                border-radius: 8px;
                padding: 5px;
                margin: 0px !important;
                height: 35px;
                position: absolute;
                bottom: 10px;
                left: 12px;
                opacity: 0.6;
              "
              href="https://alinocam.com"
              ><img
                src="@/assets/logo/alinocam/text_large.png"
                style="
                  z-index: 10;
                  user-drag: none;
                  user-select: none;
                  width: 120px;
                  font-size: 13px;
                "
            /></a>
          </v-img>
        </div>

        <video
          loop
          v-else
          class="video-player"
          autoplay
          controls
          :src="$route.query.link"
        />
      </div>

      <div
        v-else
        style="
          width: 100%;
          aspect-ratio: 16/9;
          background-color: rgba(0, 0, 0, 0.2);
        "
      >
        <div
          class="font-weight-light"
          style="
            position: absolute;
            top: 50%;
            color: rgba(255, 255, 255, 1);
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 14px;
          "
        >
          <span v-if="lang == 'en'">No Preview available</span>
          <span v-else>Keine Vorschau verfügbar</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SharediFrameView',
  data() {
    return {
      notFound: false,
      imageUrl: null,
      fetchIntervalHandle: null,
      lang: 'de',
    };
  },

  async created() {
    if (this.$route.query.link == null) {
      this.notFound = true;
      return;
    }

    if (
      this.$route.query.lang != null &&
      (this.$route.query.lang == 'de' || this.$route.query.lang == 'en')
    ) {
      this.lang = this.$route.query.lang;
    }

    if (this.$route.query.video != 'true' && this.$route.query.video != true) {
      this.fetchIntervalHandle = setInterval(() => {
        if ((new Date().getMinutes() - 2) % 5 == 0) this.getLatestImage();
      }, 10000);

      this.getLatestImage();
    } else {
      this.imageUrl = this.$route.query.link;
    }
  },

  destroyed() {
    if (this.fetchIntervalHandle != null)
      return clearInterval(this.fetchIntervalHandle);
  },

  methods: {
    async getLatestImage() {
      try {
        const image = await this.$helper.imageBase64(
          this.$route.query.link,
          'image/jpg',
        );

        if (image != null && image.startsWith('data:image/jpg;base64,')) {
          this.imageUrl = image;
          this.notFound = false;
        } else {
          this.notFound = true;
        }
      } catch (error) {
        this.notFound = true;
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.video-player {
  width: 100%;
  aspect-ratio: 16/9;
  margin-top: 7px;
}
</style>
